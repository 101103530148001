import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import DataProvider from '../../providers/DataProvider';
import { AuthContext } from '../../providers/AuthProvider';
import ExcelExportButton, { IColumn, ISheet } from '../../components/ExcelExportButton';

export interface IExcelDataSetupProps {
}

const ExcelDataSetup: React.FC<IExcelDataSetupProps> = ({ }) => {

    const dataprovider = new DataProvider();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [exportColumns, setExportColumns] = useState<IColumn[]>([]);
    const [exportData, setExportData] = useState<any>([]);
    const [sheets, setSheets] = useState<ISheet[]>([]);

    function getDateFromSeconds(seconds: number) {

        let d = new Date(1970, 0, 1, 0, 0, 0, 0);
        d.setSeconds(d.getSeconds() + seconds)
        
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const hours = d.getHours();
        const mins = d.getMinutes();
        const secs = d.getSeconds();

        return {
            year,
            month,
            day,
            hours,
            mins,
            secs,
            yyyyMMddhhmmss: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day} ${hours}:${mins}:${secs}`
        };

    }

    function calculateUserAnswerScore(userAnswerObj: any) {
        // Calculate the points, max & score from the user's answers object
        const answerValues = Object.values(userAnswerObj?.answersCorrect ?? {});
        const points = Number(answerValues.reduce((total, curVal) => Number(total) + Number(curVal), 0));
        const maxPoints = answerValues.length;
        const score = (Math.max(points, 0) / (Math.max(maxPoints, 1)));

        return {
            points,
            maxPoints,
            score
        };
    }

    function accumulateUserAnswerScores(userAnswers: any[]) {
        const totals = userAnswers.reduce((res, curr) => {
            res.points += curr.answerPoints;
            res.maxPoints += curr.answerMaxPoints;

            return res;
        }, { points: 0, maxPoints: 0 });

        return {
            points: totals.points,
            maxPoints: totals.maxPoints,
            score: (Math.max(totals.points, 0) / (Math.max(totals.maxPoints, 1)))
        };
    };

    const getUserGamesPlayedFull = async () => {

        const companyId = 'ssR94vvq0sVuVFRnEYSP';
        const startDate = undefined//new Date("2024/11/01");
        const endDate = undefined//new Date("2024/11/30");
        // const startDate = new Date("2022/10/01");
        // const endDate = new Date("2022/12/31");

        const columns = [
            { title: "ID", name: "id"},
            { title: "CompanyID", name: "companyId"},
            { title: "Company Name", name: "companyName"},
            { title: "DepartmentID", name: "departmentId"},
            { title: "Department Name", name: "departmentName"},
            { title: "UserID", name: "userId"},
            { title: "Email", name: "email"},
            { title: "First Name", name: "firstName"},
            { title: "Last Name", name: "lastName"},
            { title: "GameID", name: "gameId"},
            { title: "Game Name", name: "gameName"},
            { title: "Date Played", name: "date"},
            { title: "Date Seconds", name: "dateSeconds" },
            { title: "Accuracy", name: "accuracy"},
            { title: "Points", name: "points"},
            { title: "Max Points", name: "maxPoints"},
            { title: "Passed", name: "passed"},
            { title: "Duration", name: "duration"}
        ];

        dataprovider.getAllUsers(user)
        .then(usersResponse => {

            const users = usersResponse?.filter((u: any) => u.companyId === companyId);

            dataprovider.getUserGamesPlayed(companyId, "", "", startDate, endDate)
            .then(gamesResponse => {
                
                let data = [];

                for (let i = 0; i < gamesResponse.length; i++) {
                    const gamePlayed = gamesResponse[i];
                    const user = users.find((u: any) => u.userId == gamePlayed.context.userId);
                    
                    if (gamePlayed.context.companyId != companyId) {
                        continue;
                    }

                    let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                    d.setSeconds(d.getSeconds() + gamePlayed.date.fullDate.seconds)

                    let year = d.getFullYear();
                    let month = d.getMonth() + 1;
                    let day = d.getDate();
                    let hours = d.getHours();
                    let mins = d.getMinutes();
                    let secs = d.getSeconds();

                    data.push({
                        id: gamePlayed.id,
                        companyId: gamePlayed.context.companyId,
                        companyName: user?.companyName || "",
                        departmentId: gamePlayed.context.departmentId,
                        departmentName: user?.departmentName || "",
                        userId: gamePlayed.context.userId,
                        email: user?.email || "User not found",
                        firstName: user?.firstName || "",
                        lastName: user?.lastName || "",
                        gameId: gamePlayed.context.gameId,
                        gameName: gamePlayed.context.gameName,
                        date: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day} ${hours}:${mins}:${secs}`,
                        dateSeconds: gamePlayed.date.fullDate.seconds,
                        accuracy: gamePlayed.gameStats.answerAccuracy,
                        points: gamePlayed.gameStats.answersCorrectCount,
                        maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                        passed: gamePlayed.gameStats.isGameWon,
                        duration: gamePlayed.gameStats.playTime
                    });
                }

                setExportColumns(columns);
                setExportData(data);
                setLoading(false)

            })
            .catch(error => {
                console.log("games played error = ", error)
                window.alert(error);
                setLoading(false)
            });

        }).catch(error => {
            console.log("users erred = ", error);
            window.alert(error);
            setLoading(false);
        });

    };

    const getUserGamesPlayed = async () => {
        /*
        Get all the documents in the GamesPlayed collection of the database for the specified:
            1) Company
            2) Department
            3) User
            4) Date range (excluding limits)
        */

        let companyId = 'H7qX9Zo52K5YQBGBNJy4';
        let departmentId = '';
        let userId = 'RKTR8AYQbxeoNgJsYvxBQAnE1u02';
        let endDate = new Date(2021, 8, 29, 23, 59);
        let startDate = new Date("2023/01/01");

        dataprovider.getUserGamesPlayed(companyId, departmentId, userId, startDate, endDate)
        .then(resultsArr => {
console.log("games played")
            let data = [];
            let columns = [
                { title: "ID", name: "id"},
                { title: "CompanyID", name: "companyId"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "UserID", name: "userId"},
                { title: "GameID", name: "gameId"},
                { title: "Game Name", name: "gameName"},
                { title: "Date Played", name: "date"},
                { title: "Accuracy", name: "accuracy"},
                { title: "Points", name: "points"},
                { title: "Max Points", name: "maxPoints"},
                { title: "Passed", name: "passed"},
                { title: "Duration", name: "duration"}
            ];

            for (let i = 0; i < resultsArr.length; i++) {
                const gamePlayed = resultsArr[i];
                
                if (gamePlayed.context.companyId != companyId) {
                    continue;
                }

                let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                d.setSeconds(d.getSeconds() + gamePlayed.date.fullDate.seconds)

                let year = d.getFullYear();
                let month = d.getMonth() + 1;
                let day = d.getDate();

                data.push({
                    id: gamePlayed.id,
                    companyId: gamePlayed.context.companyId,
                    departmentId: gamePlayed.context.departmentId,
                    userId: gamePlayed.context.userId,
                    gameId: gamePlayed.context.gameId,
                    gameName: gamePlayed.context.gameName,
                    date: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}`,
                    accuracy: gamePlayed.gameStats.answerAccuracy,
                    points: gamePlayed.gameStats.answersCorrectCount,
                    maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                    passed: gamePlayed.gameStats.isGameWon,
                    duration: gamePlayed.gameStats.playTime
                });
            }

            setExportColumns(columns);
            setExportData(data);
            setLoading(false)

        })
        .catch(error => {
            setLoading(false)
            window.alert(error);
            console.log("games played error:")
            console.dir(error)
        });
    }

    const getAllUsers = async () => {
        /*
        Get all the documents in the GamesPlayed collection of the database for the specified:
            1) Company
            2) Department
            3) User
            4) Date range (excluding limits)
        */

        dataprovider.getAllUsers(user)
        .then(resultsArr => {
console.log("users done")
            let columns = [
                { title: "CompanyID", name: "companyId"},
                { title: "Company Name", name: "companyName"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "Department Name", name: "departmentName"},
                { title: "UserID", name: "userId"},
                { title: "Email", name: "email"},
                { title: "First Name", name: "firstName"},
                { title: "Last Name", name: "lastName"},
                { title: "ID Number", name: "idNumber"},
                { title: "Role", name: "role"},
                { title: "Is Disabled", name: "disabled"},
                { title: "Games Played", name: "gamesPlayed"},
                { title: "Accuracy", name: "accuracy"},
                { title: "Total Points", name: "totalPoints"}
            ];

            // for (let i = 0; i < resultsArr.length; i++) {
            //     const gamePlayed = resultsArr[i];
                
            //     let d = new Date(1970, 0, 1, 0, 0, 0, 0);
            //     d.setSeconds(d.getSeconds() + gamePlayed.date.fullDate.seconds)

            //     data.push({
            //         companyId: gamePlayed.context.companyId,
            //         departmentId: gamePlayed.context.departmentId,
            //         userId: gamePlayed.context.userId,
            //         gameName: gamePlayed.context.gameName,
            //         date: d.toLocaleString(),
            //         accuracy: gamePlayed.gameStats.answerAccuracy,
            //         points: gamePlayed.gameStats.answersCorrectCount,
            //         maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
            //         passed: gamePlayed.gameStats.isGameWon,
            //         duration: gamePlayed.gameStats.playTime
            //     });
            // }

            setExportColumns(columns);
            setExportData(resultsArr);
            setLoading(false);

        })
        .catch(error => {
            window.alert(error);
            setLoading(false);
            console.log("users erred")
        });
    }

    const getUserAnswers = async () => {

        /*
            Get all the documents in the Answers collection for a specific user
        */

        let companyId = 'awm9obZ06MApsRSkWx3i';
        let userId = 'suQEkXQQWhM2Te4A0d0ptHsfXv12';

        dataprovider.getUserAnswers(companyId, userId)
        .then(resultsArr => {

            let data = [];
            let columns = [
                { title: "ID", name: "id"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "GameID", name: "gameId"},
                { title: "CategoryID", name: "categoryId"},
                { title: "Date", name: "date"},
                { title: "Score", name: "score"},
                { title: "Answers", name: "answers"},
                { title: "Correct Answers", name: "answersCorrect"}
            ];

            let scores = 0;
            let maxScores = 0;

            for (let i = 0; i < resultsArr.length; i++) {
                const userAnswer = resultsArr[i];
                
                let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                d.setSeconds(d.getSeconds() + userAnswer.date.seconds)

                let year = d.getFullYear();
                let month = d.getMonth() + 1;
                let day = d.getDate();
                let hour = d.getHours();
                let minute = d.getMinutes();
                let second = d.getSeconds();

                data.push({
                    id: userAnswer.id,
                    departmentId: userAnswer.departmentId,
                    gameId: userAnswer.gameId,
                    categoryId: userAnswer.categoryId,
                    //date: d.toISOString(),        // Not working with the excel download
                    date: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}-${hour}-${minute}-${second}`,
                    score: userAnswer.score,
                    answers: JSON.stringify(userAnswer.answers).replace(/,/g, '|'),
                    answersCorrect: JSON.stringify(userAnswer.answersCorrect).replace(/"/g, '').replace(/,/g, '|')
                });

                let answersArray = Object.values(userAnswer.answersCorrect);
                //Object.values(ans).filter(a => a === 1).length
                let totalAnswerScore = answersArray.filter(a => a === 1).length;
                
                scores += Math.min(totalAnswerScore, 5);
                maxScores += answersArray.length;
            }

            let avg = scores / maxScores;

            setExportColumns(columns);
            setExportData(data);
            setLoading(false);

        })
        .catch(error => {
            window.alert(error);
            setLoading(false);
        });
    };

    const getAllUserAnswers = async () => {

        const companyId = 'ssR94vvq0sVuVFRnEYSP';

        dataprovider.getAllUserAnswers(companyId)
        .then(resultsArr => {

            let data = [];
            let columns = [
                { title: "Company ID", name: "companyId" },
                { title: "Department ID", name: "departmentId" },
                { title: "User ID", name: "userId" },
                { title: "Email", name: "email" },
                { title: "Answer ID", name: "answerId" },
                { title: "Date", name: "dateString" },
                { title: "Date Seconds", name: "dateSeconds" },
                { title: "Category ID", name: "categoryId" },
                { title: "Category", name: "category" },
                { title: "Game", name: "game" },
                { title: "Game ID", name: "gameId" },
                { title: "Score", name: "score" },
                { title: "Points", name: "points" },
                { title: "Max Points", name: "maxPoints" },
            ];

            let points = 0;
            let maxPoints = 0;

            for (let i = 0; i < resultsArr.length; i++) {
                const userAnswer = resultsArr[i];

                let answersArray = Object.values(userAnswer.answersCorrect);
                let totalAnswerScore = answersArray.filter(a => a === 1).length;
                
                points = totalAnswerScore;
                maxPoints = answersArray.length;

                let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                d.setSeconds(d.getSeconds() + userAnswer.date.seconds)

                let year = d.getFullYear();
                let month = d.getMonth() + 1;
                let day = d.getDate();
                let hours = d.getHours();
                let mins = d.getMinutes();
                let secs = d.getSeconds();

                data.push({ 
                    companyId: userAnswer.companyId,
                    departmentId: userAnswer.departmentId,
                    userId: userAnswer.userId,
                    email: userAnswer.email,
                    answerId: userAnswer.answerId,
                    dateString: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day} ${hours}:${mins}:${secs}`,
                    dateSeconds: userAnswer.date.seconds,
                    categoryId: userAnswer.categoryId,
                    category: userAnswer.category,
                    game: userAnswer.game,
                    gameId: userAnswer.gameId,
                    score: userAnswer.score,
                    points: points,
                    maxPoints: maxPoints
                });
            }

            setExportColumns(columns);
            setExportData(data);
            setLoading(false);
        })
        .catch(error => {
            console.log("getting all user answers failed: ", error)
            window.alert(error);
            setLoading(false);
        });
    }

    const getAllTopics = async () => {
        
        dataprovider.getAllLearningCenterTopics()
        .then(resultsArr => {
            
            let columns = [
                { title: "CompanyID", name: "companyId"},
                { title: "Company Name", name: "companyName"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "Department Name", name: "departmentName"},
                { title: "TopicID", name: "topicId"},
                { title: "Topic Name", name: "topicName"},
                { title: "Is Shared", name: "isShared"},
                { title: "Is Child", name: "isChild"},
                { title: "HostID", name: "hostId"},
                { title: "Is Loading", name: "isLoading"},
                { title: "Child DepartmentIDs", name: "childDepartments"}
            ];

            setExportColumns(columns);
            setExportData(resultsArr);
            setLoading(false);

        })
        .catch(error => {
            setLoading(false);
            window.alert(error);
        });

    };

    const resetTopicsLoadingState = async () => {

        dataprovider.resetLearningCenterTopicLoadingState("U9oTvagHhWplt9ZqT1Fs")
        .then(response => {

            if (response && response > 0) {
                window.alert(`Updated topics count is ${response}`)
            }

            getAllTopics();

        })
        .catch(error => {
            setLoading(false);
            window.alert(error);
        });
    };

    const getAllLeaderboards = async () => {
        
        dataprovider.getAllLeaderboards()
        .then(resultsArr => {
            
            let columns = [
                { title: "Leaderboard ID", name: "leaderboardId" },
                { title: "Company ID", name: "companyId" },
                { title: "Company Name", name: "companyName" },
                { title: "Department ID", name: "departmentId" },
                { title: "Department Name", name: "departmentName" },
                { title: "User ID", name: "userId" },
                { title: "User Name", name: "userName" },
                { title: "Points", name: "points" },
                { title: "Max Points", name: "maxPoints" },
                { title: "Accuracy", name: "accuracy" }
            ];

            setExportColumns(columns);
            setExportData(resultsArr);
            setLoading(false);

        })
        .catch(error => {
            setLoading(false);
            window.alert(error);
        });

    };

    const getAllGames = async () => {

        let companyId = 'InuwefELLvxyUNln7eBU';
        let departmentId = '';

        dataprovider.getAllGames(companyId, departmentId)
            .then(resultsArr => {
                console.log("getAllGames done, resultsArr = ", resultsArr)
                let columns = [
                    { title: "CompanyID", name: "companyId"},
                    { title: "Company Name", name: "companyName"},
                    { title: "DepartmentID", name: "departmentId"},
                    { title: "Department Name", name: "departmentName"},
                    { title: "GameID", name: "gameId"},
                    { title: "Game Name", name: "name"},
                    { title: "Max Correct", name: "maximumCorrect"},
                ];
    
                setExportColumns(columns);
                setExportData(resultsArr);
                setLoading(false);
    
            })
            .catch(error => {
                window.alert(error);
                setLoading(false);
                console.log("getAllGames erred")
            });
    }

    const recalculateGamesPlayedScores = async () => {
        try {
            console.log("fetching data")
            const companyId = "ssR94vvq0sVuVFRnEYSP";
            const responses = await Promise.allSettled([
                dataprovider.getUserGamesPlayed(companyId),
                dataprovider.getAllUserAnswers(companyId)
            ]);

            const allGamesPlayed = responses[0].status === 'fulfilled' ? responses[0].value : [];
            const allUserAnswers = responses[1].status === 'fulfilled' ? responses[1].value : [];

            let updateData: any[] = [];
            let updatedGamesPlayed: any[] = [];
            let notUpdatedGamesPlayed: any[] = [];
            let noAnswersFound: any[] = [];
            let multipleAnswersFound: any[] = [];

            allGamesPlayed.forEach((gamePlayed: any) => {

                if (updateData.length > 2000) {
                    return;
                }

                // Destructure some values from the object
                const departmentId = gamePlayed.context.departmentId;
                const userId = gamePlayed.context.userId;
                const gameId = gamePlayed.context.gameId;
                const gamePlayedId = gamePlayed.id;
                const gameDateSeconds = gamePlayed.date.fullDate.seconds;

                // Find the exact set of answers given for this game
                const filteredUserAnswers = allUserAnswers
                    .filter((ua: any) => {
                        const answerDateSeconds = ua.date.seconds;
                        const minComp = gameDateSeconds >= (answerDateSeconds - 300);
                        const maxComp = gameDateSeconds <= (answerDateSeconds + 300);
                        const deptComp = ua.departmentId == departmentId;
                        const userComp = ua.userId == userId;
                        const gameComp = ua.gameId == gameId;
                        const isValid = Object.keys(ua.answersCorrect || {}).length <= 5;
                        const finalComp = (userComp && gameComp && deptComp && minComp && maxComp && isValid)
                        return finalComp
                    });

                if (filteredUserAnswers?.length <= 0) {
                    noAnswersFound.push({
                        companyId: companyId,
                        departmentId: departmentId,
                        userId: userId,
                        // Game played details
                        gamePlayedId: gamePlayedId,
                        gameId: gameId,
                        gameName: gamePlayed.context.gameName,
                        date: getDateFromSeconds(gameDateSeconds).yyyyMMddhhmmss,
                        dateSeconds: gameDateSeconds,
                        accuracy: gamePlayed.gameStats.answerAccuracy,
                        points: gamePlayed.gameStats.answersCorrectCount,
                        maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                        passed: gamePlayed.gameStats.isGameWon
                    });
                    return;
                }

                // Group the filtered user answers by category ID
                let hasMultipleEntries = false;
                const groupedByCat = filteredUserAnswers.reduce((groupedArr: any[], ua: any) => {
                    const key = ua.categoryId;
                    const answerStats = calculateUserAnswerScore(ua);
                    const amendedUA = {
                        ...ua,
                        answerScore: answerStats.score,
                        answerPoints: answerStats.points,
                        answerMaxPoints: answerStats.maxPoints
                    };

                    if (!groupedArr[key]) {
                        // This category hasn't been added yet
                        groupedArr[key] = [amendedUA];
                    } else {
                        hasMultipleEntries = true;
                        // This category already exist, compare the points
                        if (groupedArr[key][0].answerPoints < amendedUA.answerPoints) {
                            // The current points is greater, insert in first position
                            groupedArr[key].unshift(amendedUA);
                        } else {
                            // The current points is less, add to bottom of array
                            groupedArr[key].push(amendedUA);
                        }
                    }

                    return groupedArr;
                }, {});

                // Check if any category has multiple entries
                if (hasMultipleEntries) {
                    multipleAnswersFound.push(Object.values(groupedByCat).flat().map((ua: any) => {
                        return {
                            companyId: companyId,
                            departmentId: departmentId,
                            userId: userId,
                            // Game played details
                            gamePlayedId: gamePlayedId,
                            gameId: gameId,
                            gameName: gamePlayed.context.gameName,
                            date: getDateFromSeconds(gameDateSeconds).yyyyMMddhhmmss,
                            dateSeconds: gameDateSeconds,
                            accuracy: gamePlayed.gameStats.answerAccuracy,
                            points: gamePlayed.gameStats.answersCorrectCount,
                            maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                            passed: gamePlayed.gameStats.isGameWon,
                            // User answer details
                            answerId: ua.answerId,
                            answerDateString: getDateFromSeconds(ua.date.seconds).yyyyMMddhhmmss,
                            answerDateSeconds: ua.date.seconds,
                            answerUserId: ua.userId,
                            answerGameId: ua.gameId,
                            answerCategoryId: ua.categoryId,
                            answerScore: ua.answerScore,
                            answerPoints: ua.answerPoints,
                            answerMaxPoints: ua.answerMaxPoints
                        }
                    }));
                }

                // Select the first element from each grouped user answer
                const finalUserAnswers = Object.values(groupedByCat).map((arrUA: any) => arrUA[0]);
                const answerStats = accumulateUserAnswerScores(finalUserAnswers);
                let gameStats = {...gamePlayed?.gameStats};

                // Now check if game played figures are the same as the calcualted figures
                let isUpdated = false;

                if (gameStats.answersCorrectCount != answerStats.points) {
                    isUpdated = true;
                    gameStats.answersCorrectCount = answerStats.points;
                }

                if (gameStats.possibleAnswersCorrectCount != answerStats.maxPoints) {
                    isUpdated = true;
                    gameStats.possibleAnswersCorrectCount = answerStats.maxPoints;
                }

                if (gameStats.answerAccuracy != answerStats.score) {
                    isUpdated = true;
                    gameStats.answerAccuracy = answerStats.score;
                }

                if (isUpdated) {
                    // Update whether game is won or not
                    gameStats.isGameWon = answerStats.score >= 0.5;
                    updateData.push({
                        id: gamePlayedId,
                        data: {
                            ...gamePlayed,
                            gameStats: { ...gameStats }
                        }
                    });
                    updatedGamesPlayed.push(finalUserAnswers.map((ua: any) => {
                        return {
                            companyId: companyId,
                            departmentId: departmentId,
                            userId: userId,
                            // Game played details
                            gamePlayedId: gamePlayedId,
                            gameId: gameId,
                            gameName: gamePlayed.context.gameName,
                            date: getDateFromSeconds(gameDateSeconds).yyyyMMddhhmmss,
                            dateSeconds: gameDateSeconds,
                            // User answer details
                            answerId: ua.answerId,
                            answerDateString: getDateFromSeconds(ua.date.seconds).yyyyMMddhhmmss,
                            answerDateSeconds: ua.date.seconds,
                            answerUserId: ua.userId,
                            answerGameId: ua.gameId,
                            answerCategoryId: ua.categoryId,
                            answerScore: ua.answerScore,
                            answerPoints: ua.answerPoints,
                            answerMaxPoints: ua.answerMaxPoints,
                            // Old Game played details
                            prevAccuracy: gamePlayed.gameStats.answerAccuracy,
                            prevPoints: gamePlayed.gameStats.answersCorrectCount,
                            prevMaxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                            prevPassed: gamePlayed.gameStats.isGameWon,
                            // New Game played details
                            newAccuracy: gameStats.answerAccuracy,
                            newPoints: gameStats.answersCorrectCount,
                            newMaxPoints: gameStats.possibleAnswersCorrectCount,
                            newPassed: gameStats.isGameWon
                        };
                    }).flat());
                } else {
                    notUpdatedGamesPlayed.push(finalUserAnswers.map((ua: any) => {
                        return {
                            companyId: companyId,
                            departmentId: departmentId,
                            userId: userId,
                            // Game played details
                            gamePlayedId: gamePlayedId,
                            gameId: gameId,
                            gameName: gamePlayed.context.gameName,
                            date: getDateFromSeconds(gameDateSeconds).yyyyMMddhhmmss,
                            dateSeconds: gameDateSeconds,
                            accuracy: gamePlayed.gameStats.answerAccuracy,
                            points: gamePlayed.gameStats.answersCorrectCount,
                            maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                            passed: gamePlayed.gameStats.isGameWon,
                            // User answer details
                            answerId: ua.answerId,
                            answerDateString: getDateFromSeconds(ua.date.seconds).yyyyMMddhhmmss,
                            answerDateSeconds: ua.date.seconds,
                            answerUserId: ua.userId,
                            answerGameId: ua.gameId,
                            answerCategoryId: ua.categoryId,
                            answerScore: ua.answerScore,
                            answerPoints: ua.answerPoints,
                            answerMaxPoints: ua.answerMaxPoints,
                        };
                    }).flat());
                }
            });

            let tempSheets: ISheet[] = [];

            if (updatedGamesPlayed.length > 0) {
                // Commit the updates to the database
                console.log("objects to be updated = ", updatedGamesPlayed.flat())
                tempSheets.push({
                    sheetName: "Updated",
                    sheetColumns: [
                        { title: "Company ID", name: "companyId" },
                        { title: "Department ID", name: "departmentId" },
                        { title: "User ID", name: "userId" },
                        // Game played details
                        { title: "Game Played ID", name: "gamePlayedId"},
                        { title: "Game ID", name: "gameId"},
                        { title: "Game Name", name: "gameName"},
                        { title: "Date Played", name: "date"},
                        { title: "Date Seconds", name: "dateSeconds" },
                        // User answer details
                        { title: "Answer ID", name: "answerId" },
                        { title: "Answer Date", name: "answerDateString" },
                        { title: "Answer Date Seconds", name: "answerDateSeconds" },
                        { title: "Answer User ID", name: "answerUserId" },
                        { title: "Answer Game ID", name: "answerGameId" },
                        { title: "Answer Category ID", name: "answerCategoryId" },
                        { title: "Answer Score", name: "answerScore" },
                        { title: "Answer Points", name: "answerPoints" },
                        { title: "Answer Max Points", name: "answerMaxPoints" },
                        // Old Game played details
                        { title: "Prev Accuracy", name: "prevAccuracy"},
                        { title: "Prev Points", name: "prevPoints"},
                        { title: "Prev Max Points", name: "prevMaxPoints"},
                        { title: "Prev Passed", name: "prevPassed"},
                        // New Game played details
                        { title: "New Accuracy", name: "newAccuracy"},
                        { title: "New Points", name: "newPoints"},
                        { title: "New Max Points", name: "newMaxPoints"},
                        { title: "New Passed", name: "newPassed"},
                    ],
                    sheetData: updatedGamesPlayed.flat()
                });
            }

            if (notUpdatedGamesPlayed.length > 0) {
                console.log("objects NOT updated = ", notUpdatedGamesPlayed.flat())
                tempSheets.push({
                    sheetName: "Not updated",
                    sheetColumns: [
                        { title: "Company ID", name: "companyId" },
                        { title: "Department ID", name: "departmentId" },
                        { title: "User ID", name: "userId" },
                        // Game played details
                        { title: "Game Played ID", name: "gamePlayedId"},
                        { title: "Game ID", name: "gameId"},
                        { title: "Game Name", name: "gameName"},
                        { title: "Date Played", name: "date"},
                        { title: "Date Seconds", name: "dateSeconds" },
                        { title: "Accuracy", name: "accuracy"},
                        { title: "Points", name: "points"},
                        { title: "Max Points", name: "maxPoints"},
                        { title: "Passed", name: "passed"},
                        // User answer details
                        { title: "Answer ID", name: "answerId" },
                        { title: "Answer Date", name: "answerDateString" },
                        { title: "Answer Date Seconds", name: "answerDateSeconds" },
                        { title: "Answer User ID", name: "answerUserId" },
                        { title: "Answer Game ID", name: "answerGameId" },
                        { title: "Answer Score", name: "answerScore" },
                        { title: "Answer Points", name: "answerPoints" },
                        { title: "Answer Max Points", name: "answerMaxPoints" }
                    ],
                    sheetData: notUpdatedGamesPlayed.flat()
                });
            }

            if (noAnswersFound.length > 0) {
                console.log("objects not found = ", noAnswersFound)
                tempSheets.push({
                    sheetName: "No Answers",
                    sheetColumns: [
                        { title: "Company ID", name: "companyId" },
                        { title: "Department ID", name: "departmentId" },
                        { title: "User ID", name: "userId" },
                        // Game played details
                        { title: "Game Played ID", name: "gamePlayedId"},
                        { title: "Game ID", name: "gameId"},
                        { title: "Game Name", name: "gameName"},
                        { title: "Date Played", name: "date"},
                        { title: "Date Seconds", name: "dateSeconds" },
                        { title: "Accuracy", name: "accuracy"},
                        { title: "Points", name: "points"},
                        { title: "Max Points", name: "maxPoints"},
                        { title: "Passed", name: "passed"},
                    ],
                    sheetData: noAnswersFound
                });
            }

            if (multipleAnswersFound.length > 0) {
                console.log("multiple answers found = ", multipleAnswersFound)
                tempSheets.push({
                    sheetName: "Multiple Answers",
                    sheetColumns: [
                        { title: "Company ID", name: "companyId" },
                        { title: "Department ID", name: "departmentId" },
                        { title: "User ID", name: "userId" },
                        // Game played details
                        { title: "Game Played ID", name: "gamePlayedId"},
                        { title: "Game ID", name: "gameId"},
                        { title: "Game Name", name: "gameName"},
                        { title: "Date Played", name: "date"},
                        { title: "Date Seconds", name: "dateSeconds" },
                        { title: "Accuracy", name: "accuracy"},
                        { title: "Points", name: "points"},
                        { title: "Max Points", name: "maxPoints"},
                        { title: "Passed", name: "passed"},
                        // User answer details
                        { title: "Answer ID", name: "answerId" },
                        { title: "Answer Date", name: "answerDateString" },
                        { title: "Answer Date Seconds", name: "answerDateSeconds" },
                        { title: "Answer User ID", name: "answerUserId" },
                        { title: "Answer Game ID", name: "answerGameId" },
                        { title: "Answer Category ID", name: "answerCategoryId" },
                        { title: "Answer Score", name: "answerScore" },
                        { title: "Answer Points", name: "answerPoints" },
                        { title: "Answer Max Points", name: "answerMaxPoints" }
                    ],
                    sheetData: multipleAnswersFound.flat()
                });
            }

            if (tempSheets.length > 0) {
                setSheets(tempSheets);
            }

            // Batch update the items
            if (updateData.length > 0) {
                await dataprovider.updateLargeDataset("GamesPlayed", updateData)
            }
        } catch (error) {
            console.log("Failed to recalculate games played figures: ", error);
        } finally {
            setLoading(false);
        }
    }

    const updateUserRegions = async () => {
        try {
            const regionUpdates: any = {
                "Region 1": "Region 1 - HASA Gauteng East",
                "Region 2": "Region 2 - HASA Gauteng North",
                "Region 3": "Region 3 - HASA Gauteng West",
                "Region 4": "Region 4 - HASA Kwa-Zulu Natal",
                "Region 5": "Region 5 - HASA Western Cape",
                "Region 6": "Region 6 - HEAD OFFICE",
                "Region 7": "Region 7 - INDEPENDENT",
                "Region 8": "Region 8 - Motus Multi Graeme",
                "Region 9": "Region 9 - Motus Multi Ridhawan",
                "Region 10": "Region 10 - Motus Multi Shane"
            };
            const regionUpdateKeys = Object.keys(regionUpdates);
            const companyId = "ssR94vvq0sVuVFRnEYSP";
            const allUsers = await dataprovider.getUsers(companyId);

            let updatePromises: any[] = [];
            allUsers.forEach((user: any) => {
                const updatedRegion: string = regionUpdateKeys.includes(user.region) ? regionUpdates[user.region] : "";

                if (updatedRegion.length > 0) {
                    updatePromises.push(dataprovider.updateUserRegion(companyId, user.id, updatedRegion));
                }
            });
            
            const responses = await Promise.allSettled(updatePromises);

            console.log('update results = ', responses);

            let tempSheets: ISheet[] = [];
            const cols: IColumn[] = [
                { title: "CompanyID", name: "company"},
                { title: "DepartmentID", name: "department"},
                { title: "UserID", name: "id"},
                { title: "Email", name: "email"},
                { title: "First Name", name: "firstName"},
                { title: "Last Name", name: "lastName"},
                { title: "Role", name: "role"},
                { title: "Region", name: "region"},
                { title: "Is Disabled", name: "disabled"}
            ];

            tempSheets.push({
                sheetName: "Original",
                sheetColumns: cols,
                sheetData: allUsers
            });

            const updatedUsers = await dataprovider.getUsers(companyId);

            tempSheets.push({
                sheetName: "Updated",
                sheetColumns: cols,
                sheetData: updatedUsers
            });

            setSheets(tempSheets);
            
        } catch (error) {
            console.log("Update users failed: ", error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        //getUserGamesPlayedFull();
        //getUserGamesPlayed();
        //getAllUsers();
        //getUserAnswers();
        //getAllUserAnswers();
        //getAllTopics();
        //resetTopicsLoadingState();
        //getAllLeaderboards();
        //getAllGames();
        recalculateGamesPlayedScores();
        //updateUserRegions();

    }, []);

    if (loading) {
        return (
            <div>Loading data</div>
        );
    } else {
        return (
            <ExcelExportButton fileName='DataExport' buttonTitle='Download' sheets={sheets?.length > 0 ? sheets : [{ sheetColumns: exportColumns, sheetData: exportData}]} />
        );
    }
}

export default ExcelDataSetup;