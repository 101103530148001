import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Grid, Typography, Container, Button, CircularProgress, makeStyles, AppBar, Toolbar } from '@material-ui/core';
import Stats from './components/Stats';
import DataProvider from '../../providers/DataProvider';
import firebase from '../../providers/FirestoreProvider';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import ReportFilter from './components/ReportFilter';
import ExportAll from './components/exports/ExcelAll';
import { useDispatch, useSelector } from 'react-redux';
import AllActions from '../../redux/actions/Index';
import { useSnackbar } from 'notistack';
import * as excel from 'exceljs';
import Roles from '../../models/enums/Roles';
import { AuthContext } from "../../providers/AuthProvider";
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'underscore';
import { QuestionTypes } from '../../models/enums/QuestionTypes';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.dark
    },
    appBar: {
        marginBottom: 20
    },
    toolbar: {
        backgroundColor: "#d6d6d6",
        minHeight: 200
    },
    warningText: {
        fontWeight: 600,
        color: "orange"
    },
    buttonProgress: {
        margin: theme.spacing(2),
    },
}));
enum State {
    LOADING = 0,
    GENERATING,
    ERROR,
    EMPTY,
    SUCCESS,
    INITIAL
}
export interface IGeneralReportProps {
}

const GeneralReport: React.FC<IGeneralReportProps> = ({ }) => {
    const { user } = useContext(AuthContext);
    const [disabled, setDisabled] = useState(false);
    const [currentState, setState] = useState<State>(State.INITIAL);
    const [isOutDated, setIsOutDated] = useState<boolean>(false);
    const [selectedFromDate, setFromDate] = useState(moment().subtract(2, 'days').toDate());
    const [selectedToDate, setToDate] = useState(moment().toDate());
    const [report, setReport] = useState<any>({});
    const [company, setCompany] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [department, setDepartment] = useState<string>("");
    const [companyCountProgress, setCompanyCountProgress] = useState<number>(0);
    const dataprovider = new DataProvider();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;
    let progress = 0;
    let recordsToCountThrough = 0;

    useEffect(() => {

        if (!company) {
            return;
        }

        getReport();

    }, [company, department, selectedFromDate, selectedToDate]);

    const calculateOffset = () => {

        let fromMoment = moment(selectedFromDate);
        let toMoment = moment(selectedToDate);
        let diffDays = toMoment.diff(fromMoment, 'days');

        return diffDays;

    };
    
    const renderStats = (state: State) => {
        switch (state) {
            case State.LOADING:
                return (
                    <Grid item container spacing={4} justify="center" alignItems="center" direction="column">
                        <Grid item>
                            <Typography variant="h4">Loading</Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )
            case State.GENERATING:
                return (
                    <Grid item container spacing={4} justify="center" alignItems="center" direction="column">
                        <Grid item>
                            <Typography variant="h4">Generating Report</Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )
            case State.EMPTY:
                return (
                    <Grid item container justify="center" alignItems="center">
                        <Typography variant="h4">No records found</Typography>
                    </Grid>
                )
            case State.ERROR:
                return (
                    <Grid item container justify="center" alignItems="center">
                        <Typography variant="h4">An error occurred</Typography>
                    </Grid>
                )
            case State.SUCCESS:
                return (
                    <Stats report={report} />
                )
            case State.INITIAL:
                return (
                    <Grid item container justify="center" alignItems="center">
                        <Typography variant="h4">Select a company and department to view stats</Typography>
                    </Grid>
                )
            default:
                return (
                    <Grid item container justify="center" alignItems="center">
                        <Typography variant="h4">Select a company and department to view stats</Typography>
                    </Grid>
                )
                break;
        }
    }

    const getReport = async (state: State = State.LOADING, callBackWhenEmpty?: Function) => {
        console.log("GET REPORT");
        if (company !== "") {
            console.log("COMPANY IS NOT EMPTY");
            setState(state);
            console.log("Getting report");
            //selected date needs to be the start tick of the day
            selectedToDate.setHours(0, 0, 0, 0);
            let report = await dataprovider.getReport(company, department, selectedToDate, calculateOffset())
            if (report !== null) {
                console.log("REPORT IS NOT NULL", report);
                // Check if the report is outdated
                let todayFull = new Date();
                let todayDate = new Date(todayFull.getFullYear(), todayFull.getMonth(), todayFull.getDate());
                let createdDate = new Date(report.createdDate?.year, report.createdDate?.month, report.createdDate?.day);
                let endDate = new Date(report.date?.year, report.date?.month, report.date?.day);

                if (createdDate < todayDate && createdDate < endDate) {
                    setIsOutDated(true);
                    console.log("REPORT IS OUTDATED");
                } else {
                    setIsOutDated(false);
                    console.log("REPORT IS NOT OUTDATED");
                }

                setReport(report);
                console.log(report);
                setState(State.SUCCESS);

            } else {

                setReport({});
                setIsOutDated(false);

                if (callBackWhenEmpty) {
                    callBackWhenEmpty();
                } else {
                    setState(State.EMPTY);
                }

            }

        }
    }

    const generateReport = async () => {
        try {
            enqueueSnackbar("Generating report", { variant: "info", anchorOrigin: { vertical: "top", horizontal: "center" } });
            setState(State.GENERATING);
            await createReport(company, department, selectedFromDate, selectedToDate);
            setState(State.SUCCESS);
            enqueueSnackbar('Report generated successfully', { variant: "success", anchorOrigin: { horizontal: "center", vertical: "top" } });
        } catch (error) {
            setState(State.ERROR);
            enqueueSnackbar('Failed to start the process to generate the report: ' + error, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "top" } });
        }

    };

    const createReport = async (companyId: string, departmentId: string, fromDate: Date, toDate: Date) => {
        try {
            let startMoment = moment(fromDate).hour(0).minute(0).second(0).millisecond(0);
            let endMoment = moment(toDate).hour(12).minute(59).second(59).millisecond(999);
            let diffDays = endMoment.diff(startMoment, 'days');

            //fromDate = fromDate first tick of the day
            fromDate.setHours(0, 0, 0, 0);
            //toDate = toDate last tick of the day
            toDate.setHours(23, 59, 59, 999);

            // Get the respective data
            const responses = await Promise.allSettled([
                dataprovider.getUserGamesPlayed(companyId, "", "", fromDate, toDate),
                dataprovider.getEmployeeUsers(companyId),
                dataprovider.getDepartmentNew(companyId)
            ]);

            const gamesPlayed = responses[0].status === 'fulfilled' ? responses[0].value : [];
            const users = responses[1].status === 'fulfilled' ? responses[1].value : [];
            const departments = responses[2].status === 'fulfilled' ? responses[2].value : [];

            let reportIDs = [];

            if (department !== "") {
                let gamesPlayedByDepartment = [...gamesPlayed].filter(item => item.context.departmentId === departmentId);

                if (gamesPlayedByDepartment.length !== 0) {
                    reportIDs.push(await createSingleReport([...gamesPlayedByDepartment], departments, users, companyId, departmentId, selectedFromDate, selectedToDate, diffDays));
                }
                else {
                    console.log(`No games played for ${departmentId}`, { variant: "info", anchorOrigin: { vertical: "top", horizontal: "center" } });
                }
            }
            else {
                reportIDs.push(await createSingleReport([...gamesPlayed], departments, users, companyId, "", selectedFromDate, selectedToDate, diffDays));
            }

            let result = (reportIDs && reportIDs.length >= 1 ? reportIDs.join() : "NONE");
            console.log(`Report IDs: ${result}`, { variant: "info", anchorOrigin: { vertical: "top", horizontal: "center" } });
            return result;

        } catch (error) {
            console.log("Failed to create report: ", error);
            return error
        }
    };

    async function genExpandedUserReport(gameFiltered: any[], filteredUsers: any[]) {
        try {
            let rows: any[] = [];

            filteredUsers.forEach(user => {
                let userGames = gameFiltered.filter(x => x.context.userId === user.id);

                userGames.forEach(game => {
                    let row = {
                        userId: user.id,
                        firstName: user.userInfo.firstName,
                        lastName: user.userInfo.lastName,
                        general: user?.userInfo?.general,
                        gameName: game.context.gameName,
                        datePlayed: game.date.fullDate.toDate().toLocaleDateString("en-GB"),
                        accuracy: game.gameStats.answerAccuracy * 100,
                        points: game.gameStats.answersCorrectCount,
                        duration: game.gameStats.playTime,
                    }

                    rows.push(row);
                })
            });
            return rows;
        } catch (error) {
            enqueueSnackbar('Failed to generate the report: ' + error, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "top" } });
        }
    }

    const createSingleReport = (gamesPlayed: any[], departments: any[], users: any[], companyId: string, departmentId: string, startDate: Date, endDate: Date, offset: number) => new Promise<any>(async (res, rej) => {
        try {
            // Disabled users should be ignored from the reporting stats
            const filteredUsers = users.filter(user => user.userInfo.disabled === null || user.userInfo.disabled === false || user.userInfo.disabled === undefined);

            //Filter games by date and by disabled users
            const gameFiltered = gamesPlayed.filter((item) => {
                if (item.date.fullDate.toDate() <= endDate && item.date.fullDate.toDate() >= startDate) {
                    let findUser = filteredUsers.find(x => x.id === item.context.userId);
                    return !!findUser;
                } else {
                    return false;
                }
            });

            const averageStats = await averages(gameFiltered);
            const top10Stats = await top10(gameFiltered, companyId);
            const byDepartmentStats = await byDepartment(gameFiltered, departments, filteredUsers);
            const gamesStats = await gameStats(gameFiltered);
            const percentagesStats = await percentages(gameFiltered);
            const participationStats = await participationReport(gameFiltered, departmentId, filteredUsers);
            const uR = await genUserReport(gameFiltered, filteredUsers, offset);
            const expandedUserReports = await genExpandedUserReport(gameFiltered, filteredUsers);
            const byUserReport = await genByUserReport(gameFiltered, filteredUsers, offset);
            const byDealerReport = await genByDealerReport(byUserReport);
            const groupedData = await genGroupedDataReport(byUserReport);
            const userAnswersData = await genUserAnswersReport(filteredUsers, departments, companyId, departmentId, startDate, endDate);
            const byRankReport = await generateByRankReport(gameFiltered, filteredUsers, departments);

            const stats = {
                offset: offset,
                companyId: companyId,
                departmentId: departmentId,
                date: {
                    fullDate: endDate,
                    day: endDate.getDate(),
                    month: endDate.getMonth(),
                    year: endDate.getFullYear()
                },
                createdDate: {
                    fullDate: new Date(),
                    day: new Date().getDate(),
                    month: new Date().getMonth(),
                    year: new Date().getFullYear()
                },
                averages: averageStats,
                top10: top10Stats,
                byDepartment: byDepartmentStats,
                gameStats: gamesStats,
                percentages: percentagesStats,
                participation: participationStats.activityStats,
                inactivePlayers: participationStats.inactivePlayers,
                userReports: uR,
                userReportsExpanded: expandedUserReports,
                userRanks: byRankReport,
                userData: byUserReport,
                dealerData: byDealerReport,
                groupedData: groupedData,
                userAnswersData: userAnswersData
            }

            setReport(stats);
            let res = await firebase.firestore().collection("Reporting").add(stats);
            let report = await firebase.firestore().collection("Reporting").doc(res.id).get();
            let reportData = report.data();
            setReport(reportData);

            setState(State.SUCCESS);
        } catch (error) {
            console.log("Failed to create single report: ", error);
            rej(error);
        }

    });
    
    const generateByRankReport = (gamesPlayed: any[], users: any[], departments: any[]) => new Promise<any>(async (res, rej) => {
        /*  Business logic:
            1)  For each user:
                1.1)    Add department & region info
                1.2)    Calc points, accuracy and games played
            2)  Assign overall rank
                2.1)    Sort according to points, accuracy, number of games played, last name and finally by first name
                2.2)    Using sorted index, assign the rank
            3)  Assign department rank
                3.1)    Group sorted list by department
                3.2)    Using sorted & grouped index, assign the rank
         */
        try {
            let rankedUsers: any[] = [];

            // Step 1: add info and calculate metrics
            await asyncForEach(users, (user: any, index: number, array: any[]) => {
                const games = gamesPlayed.filter(x => x.context.userId === user.id);
                const dept = departments.find(x => x.id === user.userInfo?.department);
                
                let rankedUser = {
                    firstName: user.userInfo?.firstName,
                    lastName: user.userInfo?.lastName,
                    department: dept?.name,
                    region: user.userInfo?.region,
                    totalPoints: 0,
                    avgAccuracy: 0,
                    totalGamesPlayed: 0,
                    rankOverall: 0,
                    rankByDepartment: 0
                }

                if (games?.length > 0) {
                    rankedUser.totalGamesPlayed = games.length;

                    let totalAcc = 0;
                    for (let i = 0; i < games.length; i++) {
                        rankedUser.totalPoints += Number(games[i].gameStats?.answersCorrectCount || 0);
                        totalAcc += Number(games[i].gameStats?.answerAccuracy || 0);
                    }

                    rankedUser.avgAccuracy = Math.round(totalAcc / games.length * 100);
                }

                rankedUsers.push(rankedUser);
            });

            // Step 2.1: sort
            rankedUsers.sort((a, b) => rankSortCompareFn(a, b));

            // Step 2.2: assign overall rank
            rankedUsers.forEach((item: any, index: number) => item.rankOverall = index + 1);

            // Step 3.1: group by department
            const groupedByDept: any[] = rankedUsers.reduce((acc: any[], item: any) => {
                if (!acc[item.department]) acc[item.department] = [];
                acc[item.department].push(item);
                return acc;
            }, {});

            // Step 3.2: assign department rank
            Object.values(groupedByDept).forEach((group: any[]) => {
                group.forEach((item: any, index: number) => item.rankByDepartment = index + 1);
            });
            
            res(rankedUsers);

        } catch (error) {
            rej(error);
        }
    });

    function rankSortCompareFn(userA: any, userB: any): number {
        // Sort by points, accuracy, number of games, last name and first name
        return (
            // Points: descending
            userA.totalPoints !== userB.totalPoints ? userB.totalPoints - userA.totalPoints
            // Accuracy: descending
            : userA.avgAccuracy !== userB.avgAccuracy ? userB.avgAccuracy - userA.avgAccuracy
            // Total games: descending
            : userA.totalGamesPlayed !== userB.totalGamesPlayed ? userB.avgAccuracy - userA.avgAccuracy
            // Last name: ascending
            : userA.lastName !== userB.lastName ? userA.lastName.localeCompare(userB.lastName)
            // First name: ascending
            : userA.firstName.localeCompare(userB.firstName)
        );
    }

    async function genUserReport(gameFiltered: any, filteredUsers: any, offset: any) {
        try {
            let urs: any[] = [];
            for (let i = 0; i < filteredUsers.length; i++) {
                const user = filteredUsers[i];
                let userGames = gameFiltered.filter((x: { context: { userId: any; }; }) => x.context.userId === user.id);
                let userReport = {
                    userId: user.id,
                    userName: user?.userInfo?.firstName + " " + user?.userInfo?.lastName,
                    gamesPlayed: userGames.length,
                    //average score is the answersCorrectCount / gamesPlayed
                    averageScore: userGames.length > 0 ? await calculateAverageScore(userGames) : 0,
                    //average time is the playTime / gamesPlayed
                    averageTime: userGames.length > 0 ? await calculateAverageTime(userGames) : 0,
                    //averageAccuracy is the answersCorrectCount / answersCount
                    averageAccuracy: userGames.length > 0 ? await calculateAverageAccuracy(userGames) : 0,
                    //totalPoints is the answersCorrectCount of all games
                    totalPoints: userGames.length > 0 ? await calculateTotalPoints(userGames) : 0,
                }
                if (userReport.gamesPlayed > 0) {
                    urs.push(userReport);
                }
            }
            return urs;
        } catch (e) {
            return e;
        }
    };
    async function calculateAverageScore(userGames: any) {
        let total = 0;
        for (let i = 0; i < userGames.length; i++) {
            const game = userGames[i];
            total += game.gameStats.answersCorrectCount;
        }
        return Math.round((total / userGames.length));
    };
    async function calculateAverageTime(userGames: any) {
        let total = 0;
        for (let i = 0; i < userGames.length; i++) {
            const game = userGames[i];
            total += game.gameStats.playTime;
        }
        return Math.round((total / userGames.length));
    };
    async function calculateAverageAccuracy(userGames: any) {
        let total = 0;
        for (let i = 0; i < userGames.length; i++) {
            const game = userGames[i];
            total += game.gameStats.answerAccuracy;
        }
        return Math.round((total / userGames.length * 100));
    };
    async function calculateTotalPoints(userGames: any) {
        let total = 0;
        for (let i = 0; i < userGames.length; i++) {
            const game = userGames[i];
            total += game.gameStats.answersCorrectCount;
        }
        return Math.round(total);
    };

    const participationReport = (gamesPlayed: any[], departmentId: string, allEnabledUsers: any[]) => new Promise<any>(async (res, rej) => {

        console.log("Generating Participation Report");
        console.log("Games Played: ", gamesPlayed.length);
        console.log("Department ID: ", departmentId);
        console.log("All Enabled Users: ", allEnabledUsers.length);

        let inactiveUsers: any[] = [];
        let registeredUsers = 0;

        try {
            if (departmentId === "") {
                if (allEnabledUsers.length !== 0) {
                    registeredUsers = allEnabledUsers.length;
                    for (let i = 0; i < allEnabledUsers.length; i++) {
                        const currentUser = allEnabledUsers[i];
                        console.log("Checking user: ", currentUser.userInfo.email);
                        if (!gamesPlayed.some(g => g.context.userId === currentUser.id)) {
                            console.log("User has not played any games: ", currentUser.userInfo.email);
                            inactiveUsers.push(currentUser);
                        }
                    }
                }

            } else {
                let departmentUsers = allEnabledUsers.filter(x => x.userInfo.department === departmentId);
                if (departmentUsers.length !== 0) {
                    registeredUsers = departmentUsers.length;
                    for (let i = 0; i < departmentUsers.length; i++) {
                        const currentUser = departmentUsers[i];
                        console.log("Checking user: ", currentUser.userInfo.email);
                        if (!gamesPlayed.some(g => g.context.userId === currentUser.id)) {
                            console.log("User has not played any games: ", currentUser.userInfo.email);
                            inactiveUsers.push(currentUser);
                        }
                    }
                }
            }

            res(
                {
                    inactivePlayers: inactiveUsers,
                    activityStats: {
                        registeredUsers: registeredUsers,
                        inactiveUsers: inactiveUsers.length,
                        activeUsers: registeredUsers - inactiveUsers.length,
                        ratio: (registeredUsers - inactiveUsers.length) / registeredUsers
                    }
                }
            );
        } catch (error) {
            console.log("FAILED TO CREATE PARTICIPATION REPORT: ", error);
            res(
                {
                    inactivePlayers: [],
                    activityStats: {
                        registeredUsers: 0,
                        inactiveUsers: 0,
                        activeUsers: 0,
                        ratio: 0
                    }
                }
            );
        }
    });
    async function asyncForEach(array: any[], callback: any) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
    const averages = (data: any[]) => new Promise<any>(async (res, rej) => {
        const gamesPlayed = data.length;
        const userArr: string[] = [];
        let userCount = 0;
        let points = 0;
        let wins = 0;

        await asyncForEach(data, (item: any, index: number, array: any[]) => {
            if (!userArr.includes(item.context.userId)) {
                userArr.push(item.context.userId);
                userCount++;
            }
            points += item.gameStats.answersCorrectCount;
            if (item.gameStats.isGameWon)
                wins++
        });
        res({
            avgGamesPlayed: Math.round(((gamesPlayed / userCount) + Number.EPSILON) * 100) / 100,
            avgPoints: Math.round(((points / userCount) + Number.EPSILON) * 100) / 100,
            avgWins: Math.round(((wins / userCount) + Number.EPSILON) * 100) / 100
        });
    });
    function getGeneralField(data: any): string {
        if (data) {
            try {
                let userInfo = data.userInfo;

                if (userInfo.general) {
                    return userInfo.general;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        } else {
            return "";
        }
    }
    const top10 = (data: any[], companyId: string) => new Promise<any>(async (res, rej) => {
        const usersGrouped: any[] = [];
        try {
            data.forEach(item => {
                let object = usersGrouped.find(obj => obj.userId === item.context.userId);

                if (object) {
                    object.gamesPlayed++;
                    object.points += item.gameStats.answersCorrectCount;
                    object.answersCorrect += item.gameStats.answersCorrectCount;
                    object.posibleAnswerCorrect += item.gameStats.possibleAnswersCorrectCount;
                    object.accuracy = object.answersCorrect / object.posibleAnswerCorrect;
                } else {
                    usersGrouped.push({
                        userId: item.context.userId,
                        answersCorrect: item.gameStats.answersCorrectCount,
                        gamesPlayed: 1,
                        points: item.gameStats.answersCorrectCount,
                        posibleAnswerCorrect: item.gameStats.possibleAnswersCorrectCount,
                        accuracy: item.gameStats.answersCorrectCount / item.gameStats.possibleAnswersCorrectCount
                    })
                }
            });

            //Fix any scores that has above 100%
            for (let i = 0; i < usersGrouped.length; i++) {
                const userRecord = usersGrouped[i];

                if (userRecord.accuracy > 1) {
                    usersGrouped[i].accuracy = 1;
                }
            }

            let topPoints = [...usersGrouped].sort((a, b) => {
                if (a.points < b.points) {
                    return 1;
                } else {
                    return -1;
                }
            });

            let topGames = [...usersGrouped].sort((a, b) => {
                if (a.gamesPlayed < b.gamesPlayed) {
                    return 1;
                } else {
                    return -1;
                }
            });

            let topAccuracy = [...usersGrouped].sort((a, b) => {
                if (a.accuracy < b.accuracy) {
                    return 1;
                } else {
                    return -1;
                }
            });

            const resource = `Companies/${companyId}/Users`;

            for (let i = 0; i < topPoints.length; i++) {
                const item = topPoints[i];

                const user = await firebase.firestore().collection(resource).doc(item.userId).get();

                item.name = user.data()?.userInfo.firstName;
                item.surname = user.data()?.userInfo.lastName;
                item.email = user.data()?.userInfo.email;
                item.general = getGeneralField(user.data());
            }

            for (let i = 0; i < topGames.length; i++) {
                const item = topGames[i];

                const user = await firebase.firestore().collection(resource).doc(item.userId).get();

                item.name = user.data()?.userInfo.firstName;
                item.surname = user.data()?.userInfo.lastName;
                item.email = user.data()?.userInfo.email;
                item.general = getGeneralField(user.data());
            }

            for (let i = 0; i < topAccuracy.length; i++) {
                const item = topAccuracy[i];

                const user = await firebase.firestore().collection(resource).doc(item.userId).get();

                item.name = user.data()?.userInfo.firstName;
                item.surname = user.data()?.userInfo.lastName;
                item.email = user.data()?.userInfo.email;
                item.general = getGeneralField(user.data());
            }

            res({
                topPoints: topPoints,
                topGamesPlayed: topGames,
                topAccuracy: topAccuracy
            });
        } catch (error) {
            res(null);
        }
    });
    const byDepartment = (data: any[], departments: any[], users: any[]) => new Promise<any[]>(async (res, rej) => {
        let found = false;
        let departmentGrouped: any[] = [];
        data.forEach(item => {
            found = false;
            const department = [...departments].filter(dep => dep.id === item.context.departmentId)[0];

            departmentGrouped.forEach(groupedDepartment => {
                if (item.context.departmentId === groupedDepartment.departmentId) {

                    let containsUser = groupedDepartment.activePlayers.includes(item.context.userId);

                    if (!containsUser) {
                        groupedDepartment.activePlayers.push(item.context.userId);
                        groupedDepartment.activePlayerCount++;
                    }

                    groupedDepartment.gamesPlayed++;
                    groupedDepartment.points += item.gameStats.answersCorrectCount;
                    groupedDepartment.answersCorrect += item.gameStats.answersCorrectCount;
                    groupedDepartment.posibleAnswerCorrect += item.gameStats.possibleAnswersCorrectCount;
                    groupedDepartment.accuracy = groupedDepartment.answersCorrect / groupedDepartment.posibleAnswerCorrect;
                    groupedDepartment.averagePoints = groupedDepartment.answersCorrect / groupedDepartment.activePlayers.length;

                    found = true;
                }
            });

            if (!found) {
                departmentGrouped.push({
                    name: department.name,
                    departmentId: item.context.departmentId,
                    answersCorrect: item.gameStats.answersCorrectCount,
                    gamesPlayed: 1,
                    points: item.gameStats.answersCorrectCount,
                    posibleAnswerCorrect: item.gameStats.possibleAnswersCorrectCount,
                    accuracy: item.gameStats.answersCorrectCount / item.gameStats.possibleAnswersCorrectCount,
                    activePlayers: [item.context.userId],
                    averagePoints: item.gameStats.answersCorrectCount,
                    activePlayerCount: 1,
                    //userCount: department.userCount ? department.userCount : 0  /*unreliable since the userCount field is not always kept up to date*/
                    userCount: users?.length <= 0 ? 0 : users.filter(u => u.userInfo.department == department.id).length
                });
            }
        });

        res(departmentGrouped);

    });
    function fixScore(answer: number) {
        try {
            if (answer > 1) {
                return 1;
            } else {
                return answer;
            }
        } catch (error) {
            return 0;
        }
    }
    const gameStats = (data: any[]) => new Promise<any[]>(async (res, rej) => {
        let found = false;
        let gamesGrouped: any[] = [];
        data.forEach(item => {
            found = false;
            gamesGrouped.forEach(game => {
                if (item.context.gameId === game.gameId) {

                    game.gamesPlayed++;
                    game.timePlayed += item.gameStats.playTime;
                    game.points += item.gameStats.answersCorrectCount;
                    game.answersCorrect += item.gameStats.answersCorrectCount;
                    game.posibleAnswerCorrect += item.gameStats.possibleAnswersCorrectCount;
                    game.accuracy = game.answersCorrect / game.posibleAnswerCorrect;
                    game.averagePoints = game.points / game.activePlayers.length;
                    game.averageAccuracy = fixScore(game.answersCorrect / game.posibleAnswerCorrect);
                    found = true;
                }
            });

            if (!found) {
                gamesGrouped.push({
                    gameId: item.context.gameId,
                    gameName: item.context.gameName,
                    answersCorrect: item.gameStats.answersCorrectCount,
                    gamesPlayed: 1,
                    points: item.gameStats.answersCorrectCount,
                    posibleAnswerCorrect: item.gameStats.possibleAnswersCorrectCount,
                    accuracy: item.gameStats.answersCorrectCount / item.gameStats.possibleAnswersCorrectCount,
                    activePlayers: [item.context.userId],
                    averagePoints: item.gameStats.answersCorrectCount,
                    averageAccuracy: item.gameStats.answerAccuracy,
                    timePlayed: item.gameStats.playTime
                });
            }
        });
        //Fix any scores that has above 100%
        for (let i = 0; i < gamesGrouped.length; i++) {
            const item = gamesGrouped[i];

            if (item.accuracy > 1) {
                gamesGrouped[i].accuracy = 1;
            }
        }

        res(gamesGrouped);

    });
    const percentages = (data: any[]) => new Promise<any>(async (res, rej) => {
        const count = data.length;
        if (count < 4) {
            res({
                bottom25: 0,
                mid50: 0,
                top25: 0
            });
        }

        let midCount = 0; // mid 50
        let sideCount = 0; //bottom 25 and top 25

        const devided = count / 4;

        if (count % 4 === 0) {
            midCount = devided * 2;
            sideCount = devided;
        } else {
            let midLeftOver = (devided * 2) - Math.floor(devided * 2); //Decimal left over after deviding
            let sideLeftOver = (devided - Math.floor(devided)) * 2; //Decimal left over after deviding
            let leftOver = midLeftOver + sideLeftOver;

            midCount = Math.floor(devided * 2);
            sideCount = Math.floor(devided);

            //Add left over to mid50 or to bottom25/top25
            if (leftOver % 2 === 0) {
                sideCount++;
            } else {
                midCount++;
            }
        }

        let sortedArr = [...data].sort((a, b) => {
            if (a.gameStats.answersCorrectCount < b.gameStats.answersCorrectCount) {
                return -1;
            } else {
                return 1;
            }
        });

        let bottom25 = [...sortedArr].splice(0, sideCount);
        let mid50 = [...sortedArr].splice(sideCount - 1, midCount);
        let top25 = [...sortedArr].splice(midCount - 1, sideCount);

        const calcTotals = (arr: any[]) => {

            let stats = {
                points: 0,
                gamesPlayed: 0,
                timeSpent: 0
            }

            arr.forEach(item => {
                stats.points += item.gameStats.answersCorrectCount;
                stats.gamesPlayed++;
                stats.timeSpent += item.gameStats.playTime;
            });

            return stats;
        }

        res({
            bottom25: calcTotals(bottom25),
            mid50: calcTotals(mid50),
            top25: calcTotals(top25)
        });
    });

    async function getAllUserReport() {
        enqueueSnackbar('Generating report...Please Dont Close Window', { variant: "info", anchorOrigin: { horizontal: "center", vertical: "top" } });
        setDisabled(true);
        try {
            let allCompanies = await dataprovider.getCompanies()
            recordsToCountThrough = allCompanies.length;
            //filter the companies to find the id of LDUIlWoWzoSYxVgYyCya
            // allCompanies = allCompanies.filter(c => c.id == 'LDUIlWoWzoSYxVgYyCya');
            let builtCompanies = [];
            let gamesPlayedCollection = await firebase.firestore()
                .collection("GamesPlayed")
                .where('date.fullDate', '>=', new Date(selectedFromDate.getFullYear(), selectedFromDate.getMonth(), selectedFromDate.getDate()))
                .where('date.fullDate', '<=', new Date(selectedToDate.getFullYear(), selectedToDate.getMonth(), selectedToDate.getDate() + 1))      // The end date is exclusive due to time component
                .get();

            let gamesPlayed = gamesPlayedCollection.docs.map((doc) => {
                return {
                    id: doc.id,
                    data: doc.data()
                }
            });

            for (const company of allCompanies) {
                let companyDepartments = await firebase.firestore().collection(`Companies/${company.id}/Departments/`).get();
                let mappedDepartments = companyDepartments.docs.map((doc) => {
                    return {
                        id: doc.id,
                        data: doc.data()
                    }
                });
                let companyUsers = await firebase.firestore().collection(`Companies/${company.id}/Users/`).get();
                let mappedUsers = companyUsers.docs.map((doc) => {
                    return {
                        id: doc.id,
                        data: doc.data()
                    }
                });

                let companyGamesPlayed = gamesPlayed.filter((gamePlayed) => {
                    return gamePlayed.data.context.companyId === company.id
                });

                let companyObject = {
                    id: company.id,
                    name: company.name,
                    departments: mappedDepartments,
                    users: mappedUsers,
                    companyGames: companyGamesPlayed
                }
                builtCompanies.push(companyObject);
            }
            enqueueSnackbar('Processing data...', { variant: "info", anchorOrigin: { horizontal: "center", vertical: "top" } });
            processIncomingData(builtCompanies);
            //Process Each Company To Build Out a row For Each User and their Stats
            setDisabled(false);
        }
        catch (e) {
            setState(State.ERROR);
            // @ts-ignore
            enqueueSnackbar('Failed to generate report: ' + (e?.message || e), { variant: "error", anchorOrigin: { horizontal: "center", vertical: "top" } });
        }
    }
    function processIncomingData(builtCompanies: any[]) {
        //foreach company create a new WorkSheet

        let Roles = {
            "Employee": 1,
            "Manager": 2,
            "Admin": 3,
            "Company Manager": 4
        }

        let excelDocument = new excel.Workbook();
        builtCompanies.forEach((company) => {            
            let companyWorksheet = excelDocument.addWorksheet(company.name);
            let companyUsers = company.users;
            let companyDepartments = company.departments;
            let companyGames = company.companyGames;


            companyWorksheet.addRow(['Name', 'Surname', 'General', 'Department', 'Game Name', 'Date  Played', 'Accuracy', 'Points', 'Time Played']);
            //create a header row with bold text
            companyWorksheet.getRow(1).font = { bold: true };
            companyWorksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
            companyWorksheet.getRow(1).height = 30;
            companyWorksheet.getRow(1).eachCell((cell: any) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
            //row 1 has filters
            companyWorksheet.autoFilter = 'A1:I1';

            //outside border
            companyWorksheet.eachRow((row: any, rowNumber: any) => {
                row.eachCell((cell: any, colNumber: any) => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });

            console.log('companyUsers', companyUsers);

            //create a row for each user
            companyUsers.forEach((user: any) => {
                let firstName = user?.data?.userInfo?.firstName;
                let lastName = user?.data?.userInfo?.lastName;
                //get the role from Roles based on user.data.userInfo.role
                let role = user?.data?.userInfo?.role == 1 ? 'Employee' : user?.data?.userInfo?.role == 2 ? 'Manager' : user?.data?.userInfo?.role == 3 ? 'Admin' : 'Company Manager';
                let department = companyDepartments.find((department: any) => {
                    return department.id === user.data.userInfo.department;
                });
                console.log('department', department);
                let departmentName = department ? department.data.name : 'No Department Found';
                let userGames = companyGames.filter((gamePlayed: { data: { context: { userId: any; }; }; }) => gamePlayed.data.context.userId === user.id);
                userGames.forEach((gamePlayed: any) => {
                    let gameName = gamePlayed?.data?.context?.gameName;
                    let datePlayed = gamePlayed?.data?.date?.fullDate.toDate().toLocaleDateString("en-GB");
                    let accuracy = gamePlayed?.data?.gameStats?.answerAccuracy * 100;
                    let points = gamePlayed?.data?.gameStats?.answersCorrectCount;
                    let timePlayed = gamePlayed?.data?.gameStats?.playTime;

                    companyWorksheet.addRow([firstName, lastName, role, departmentName, gameName, datePlayed, accuracy, points, timePlayed]);
                });
            });

            //calculate the current progress
            progress = Math.round((builtCompanies.indexOf(company) / builtCompanies.length) * 100);
        });
        console.log('Done Processing Rows, Start Saving File!')

        let formattedFromDate = `${selectedFromDate.getDate()}-${selectedFromDate.getMonth() + 1}-${selectedFromDate.getFullYear()}`;
        let formattedToDate = `${selectedToDate.getDate()}-${selectedToDate.getMonth() + 1}-${selectedToDate.getFullYear()}`;

        excelDocument.xlsx.writeBuffer().then((data: any) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `All User Report - ${formattedFromDate} to ${formattedToDate}.xlsx`);
        });
    }
    const saveAs = (blob: any, filename: any) => {
        //save file from blob
        console.log('Saving File!')
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        console.log('File Saved!')

        //alert user that file has been saved
        enqueueSnackbar('Report has been generated and saved to your downloads folder', { variant: "success", anchorOrigin: { horizontal: "center", vertical: "top" } });
    }

    async function generateSecretReport() {
        //input should be the company name or id
        var input = prompt("Please enter the company id");
        if (input == null || input == "") {
            alert("User cancelled the prompt.");
        }
        else {
            //LDUIlWoWzoSYxVgYyCya is the company id
            //Call-Lab is the company name

            var company = await dataprovider.getCompany(input);
            if (company) {
                alert("Company Found - generating report");
                await processCompany(company);
            }
        }
    }
    async function processCompany(company: any) {
        console.log('Processing Company: ', company);
        var allUsers = await dataprovider.getUsers(company.id);
        //alert to how many users were found
        alert(`Found ${allUsers.length} users`);
    }

    async function genByUserReport(gameFiltered: any, filteredUsers: any, offset: any) {
        //fields that are needed after report is generated is name, surname, email, general, accuracy, topPoints, topgamesPlayed, region
        try {
            let rows: any[] = [];

            filteredUsers.forEach((user: { id: any; userInfo: { firstName: string; lastName: string; general: string; region: string; email: string }; }) => {
                let userGames = gameFiltered.filter((x: { context: { userId: any; }; }) => x.context.userId === user.id);
                console.log("USER : :", user);
                userGames.forEach((game: any) => {
                    console.log("GAME : : : ", game);
                    let row = {
                        userId: user.id,
                        firstName: user.userInfo.firstName,
                        lastName: user.userInfo.lastName,
                        email: user.userInfo.email,
                        general: user?.userInfo?.general,
                        gameName: game.context.gameName,
                        gameDate: game.date.fullDate.toDate().toLocaleDateString("en-GB"),
                        accuracy: game.gameStats.answerAccuracy * 100,
                        points: game.gameStats.answersCorrectCount,
                        region: user.userInfo.region ? user.userInfo.region : 'No Region Selected'
                    }

                    rows.push(row);
                })
            });
            
            //Group the Rows by Game Name and then by User Id
            //each row should contain the following fields: Name, Surname, EMail, General, Accuracy, Points, GameName,Top Games Played, Region
            //Top Games Played should be the number of games played that had an accuracy of 70% or higher

            //group the rows by game name user id and then return a row for each game name and user id with the total points and accuracy added together and divided by the number of games played'
            let groupedRows = rows.reduce((r: any, a: { userId: any; gameName: any; accuracy: any; points: any; }) => {
                r[a.userId + a.gameName] = r[a.userId + a.gameName] || [];
                r[a.userId + a.gameName].push(a);
                return r;
            }, Object.create(null));

            console.log('Grouped Rows: ', groupedRows);

            //foreach of the grouped rows, add the total points and accuracy together and divide by the number of games played
            let finalRows: any[] = [];
            Object.keys(groupedRows).forEach((key: any) => {
                let totalPoints = 0;
                let totalAccuracy = 0;
                let totalGamesPlayed = 0;
                let topGamesPlayed = 0;
                groupedRows[key].forEach((row: { points: any; accuracy: any; }) => {
                    // totalPoints += row.points;
                    //Total Points should be the Max Points
                    if (row.points > totalPoints) {
                        totalPoints = row.points;
                    }

                    totalAccuracy += row.accuracy;
                    totalGamesPlayed++;
                    if (row.accuracy >= 70) {
                        topGamesPlayed++;
                    }
                });

                let finalRow = {
                    userId: groupedRows[key][0].userId,
                    firstName: groupedRows[key][0].firstName,
                    lastName: groupedRows[key][0].lastName,
                    email: groupedRows[key][0].email,
                    general: groupedRows[key][0].general,
                    gameName: groupedRows[key][0].gameName,
                    gameDate: groupedRows[key][0].gameDate,
                    accuracy: Math.round(totalAccuracy / totalGamesPlayed),
                    points: totalPoints,
                    topGamesPlayed: topGamesPlayed,
                    region: groupedRows[key][0].region
                }

                finalRows.push(finalRow);
            });

            console.log('Final Rows: ', finalRows);
            //return finalRows;
            return rows;
        } catch (error) {
            enqueueSnackbar('Failed to generate the report: ' + error, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "top" } });
        }
    }
    async function genByDealerReport(userData: any) {
        //TODO: The Data Comming into this function needs to be reduced by the User Id, and the Accuracy, Points, GameName, Region should be added together and divided by the number of games played
        //Once grouped the data should be sorted by general 
        try {
            
            //The rows should be reduced by the user id, it does not matter what game they played, this report will show their totals across all games that they played

            let groupedRows = userData.reduce((r: any, a: { userId: any; accuracy: any; points: any; }) => {
                r[a.userId] = r[a.userId] || [];
                r[a.userId].push(a);
                return r;
            }, Object.create(null));

            console.log('Grouped Rows: THIS ', groupedRows);

            //foreach of the grouped rows, add the total points and accuracy together and divide by the number of games played
            let finalRows: any[] = [];
            Object.keys(groupedRows).forEach(async (key: any) => {
                let totalPoints = 0;
                let totalAccuracy = 0;
                let totalGamesPlayed = 0;
                groupedRows[key].forEach((row: { points: any; accuracy: any; }) => {
                    totalPoints += row.points;
                    totalAccuracy += row.accuracy;
                    totalGamesPlayed++;
                });

                let finalRow = {
                    userId: groupedRows[key][0].userId,
                    firstName: groupedRows[key][0].firstName,
                    lastName: groupedRows[key][0].lastName,
                    email: groupedRows[key][0].email,
                    general: groupedRows[key][0].general,
                    accuracy: Math.round(totalAccuracy / totalGamesPlayed),
                    points: Math.round(totalPoints / totalGamesPlayed),
                    dealerPercentage: await getDealerPercentage(userData, groupedRows[key][0].general),
                    region: groupedRows[key][0].region
                }

                finalRows.push(finalRow);
            });

            console.log('Final Rows: ', finalRows);
            return finalRows;
            
        } catch (error) {
            enqueueSnackbar('Failed to generate the report: ' + error, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "top" } });
        }
    }

    async function genGroupedDataReport(userData:any) {
        try {
            //TODO: The Data Comming into this function contains Rows, each row contains a record of Name, Surname, EMail, General, Accuracy, Points, GameName, Region
            //What the function needs to do is sort the data by Region, and then add a blank row between each Region
            
            let sortedUsers: any[] = [];
            //sort the data by region
            sortedUsers = userData.sort((a: { region: string; }, b: { region: string; }) => (a.region > b.region) ? 1 : -1);
            console.log('Sorted Users: ', sortedUsers);

            return sortedUsers;
        } catch (error) {
            
        }
    }
    
    async function genUserAnswersReport(users: any[], departments: any[], companyId: string, departmentId: string, startDate: Date, endDate: Date) {

        const getUserAnswersData = (user: any, companyName: string, departmentName: string) => {

            return new Promise(async (res, rej) => {

                try {
                    const answersCollection = await firebase.firestore()
                        .collection(`Companies/${companyId}/Users/${user?.id}/Answers`)
                        .where('date', '>=', startDate)
                        .where('date', '<=', endDate)
                        .get();
                    const answers: any[] = answersCollection.docs.map(item => {
                        return {
                            ...item.data(),
                            id: item.id
                        }
                    });
                    
                    if (answers?.length > 0) {

                        let result: any = [];
                        const allowedTypes = [QuestionTypes.multiChoice, QuestionTypes.fillInTheBlank, QuestionTypes.statementBlanking];
                        const userName = `${user?.userInfo?.firstName || ""} ${user?.userInfo?.lastName || ""}`.trim() || "Not available";
                        const email = user?.userInfo?.email || "Not available";

                        answers.forEach(ans => {

                            let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                            d.setSeconds(d.getSeconds() + ans?.date?.seconds || 0)
            
                            const def = {
                                companyName: companyName || "Not available",
                                departmentName: departmentName || "Not available",
                                userName: userName,
                                email: email,
                                gameName: ans?.game || "Not available",
                                categoryName: ans?.category || "Not available",
                                datePlayed: ans?.date ? moment(d).format("DD/MM/yyyy") : "Not available"
                            };

                            if (ans?.answers?.length > 0) {
                                ans.answers.forEach((a: any) => {
                                    result.push({
                                        ...def,
                                        questionText: a?.questionText || "Not available",
                                        questionType: a?.questionType || "Not available",
                                        correctAnswer: allowedTypes.includes(a?.questionTypeId) ? a.correctAnswer : "Complex",
                                        userAnswer: allowedTypes.includes(a?.questionTypeId) ? a.userAnswer : "Complex",
                                        score: a?.score || 0
                                    });
                                });
                            } else {
                                result.push({
                                    ...def,
                                    questionText: "No answers",
                                    questionType: "No answers",
                                    correctAnswer: "No answers",
                                    userAnswer: "No answers",
                                    score: 0
                                });
                            }
                        });

                        res(result);

                    } else {
                        res([]);
                    }

                } catch (e) {
                    rej(e);
                }

            });

        };

        const getAllUsersAnswersData = () => {

            return new Promise((res, rej) => {
                try {
                    const filteredUsers = departmentId?.length > 0 ? users?.filter(u => u.userInfo.department == departmentId) : users;

                    if (!filteredUsers?.length) {
                        return [];
                    }
            
                    let promises: any[] = [];
                    filteredUsers.forEach(u => {
                        const dept = departments.find(d => d.id == u.userInfo?.department);
                        promises.push(getUserAnswersData(u, companyName, dept?.name));
                    });
            
                    Promise.allSettled(promises).then(responses => {
            
                        let rowData: any[] = [];

                        responses.forEach(r => {
                            if (r.status === "fulfilled") rowData = rowData.concat(r.value);
                        });
                        
                        res(rowData);

                    });
            
                } catch {
                    rej([]);
                }
            });

        };

        return await getAllUsersAnswersData();

    };

    return (
        <Fragment>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Grid container justify="space-between">

                        <Grid item xs={12} sm={4}>
                            <ReportFilter
                                disabled={currentState == State.LOADING || currentState == State.GENERATING}
                                submit={(company: any, department: any) => {

                                    setCompany(company.id);
                                    setCompanyName(company.name);
                                    setDepartment(department?.id);
                                    dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));

                                }}
                            />
                        </Grid>

                        <Grid item container xs={12} sm={7} spacing={2}>
                            <Grid item container spacing={2} justify="flex-end">
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="From"
                                            inputVariant="outlined"
                                            disabled={currentState == State.LOADING || currentState == State.GENERATING}
                                            maxDate={selectedToDate}
                                            value={selectedFromDate}
                                            onChange={(date) => {
                                                let newDate = date ? date : moment();
                                                setFromDate(newDate.toDate());
                                            }} />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="To"
                                            inputVariant="outlined"
                                            disabled={currentState == State.LOADING || currentState == State.GENERATING}
                                            minDate={selectedFromDate}
                                            maxDate={moment().toDate()}
                                            value={selectedToDate}
                                            onChange={(date) => {
                                                let newDate = date ? date : moment();
                                                setToDate(newDate.toDate());
                                            }} />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            {
                                currentState == State.EMPTY
                                &&
                                <Grid item container justify="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { getReport(State.GENERATING, generateReport) }}
                                    >
                                        Generate Report
                                    </Button>
                                </Grid>
                            }
                            {
                                isOutDated == true && (currentState == State.SUCCESS)
                                &&
                                <>
                                    <Grid item xs={12} container direction="row">
                                        <Grid item xs={6}>
                                            <Typography className={classes.warningText}>
                                                This report may be outdated
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} container justify="flex-end">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={generateReport}
                                            >
                                                Regenerate Report
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            <Grid item container justify="flex-end">
                                <ExportAll report={report} state={currentState} />
                            </Grid>
                            <Grid item container justify="flex-end">
                                {!disabled && <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={getAllUserReport}
                                    disabled={disabled}
                                    style={{
                                        visibility: user?.role == Roles.admin ? 'visible' : 'hidden'
                                    }}
                                >
                                    Generate All User Report
                                </Button>
                                }
                                {disabled && <CircularProgress size={50} className={classes.buttonProgress} value={companyCountProgress} />}

                            </Grid>
                        </Grid>

                    </Grid>
                </Toolbar>
                <LinearProgress variant="determinate" value={progress} />
            </AppBar>
            <Container>
                {
                    renderStats(currentState)
                }
            </Container>
        </Fragment>

    );
}

export default GeneralReport;



function getDealerPercentage(userData: any, general: any) {
    //TODO: this function should take in all the user rows and then calculate the percentage of the dealer across all the games played

    let dealerCount = 0;
    let totalGamesPlayed = 0;
    userData.forEach((row: { general: any; }) => {
        if (row.general == general) {
            dealerCount++;
        }
        totalGamesPlayed++;
    }
    );

    return Math.round((dealerCount / totalGamesPlayed) * 100);
    
}

