import React from 'react';
import MaterialTable from 'material-table';
import { 
    Grid, 
    AppBar, 
    Toolbar, 
    makeStyles, 
    Typography
} from '@material-ui/core';
import ExportDefault from './exports/ExportDefault';

const useStyles = makeStyles((theme) => ({
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IUserRankings {
    data: any[];
}

const UserRankings: React.FC<IUserRankings> = ({ data }) => {

    const classes = useStyles();

    const columns = [
        { title: "Rank (Overall)", field: "rankOverall" },
        { title: "Rank (Department)", field: "rankByDepartment" },
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "lastName" },
        { title: "Department", field: "department" },
        { title: "Region", field: "region" },
        { title: "Total Points", field: "totalPoints" },
        { title: "Average Accuracy", field: "avgAccuracy" },
        { title: "Total Games Played", field: "totalGamesPlayed" }
    ];

    const tableOptions = {
        paging: true,
        search: false,
        showTitle: false,
        actionsColumnIndex: -1,
        pageSizeOptions: [10, 25, 50, 100, 500]
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6">User Rankings</Typography>
                            </Grid>
                            <Grid item>
                                <ExportDefault data={data || []} columns={columns} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item>
                <MaterialTable style={{ width: '100%', overflow: 'auto', height: '400px' }}
                    columns={columns}
                    data={data || []}
                    options={tableOptions}
                />
            </Grid>
        </Grid>
    );
}

export default UserRankings;